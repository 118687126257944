<template>
  <v-dialog
    :value="mostrar"
    content-class="dialog-ocorrencias-filtros"
    @click:outside="$emit('cancelar')"
    @keydown.esc="$emit('cancelar')"
  >
    <v-card>
      <v-toolbar color="grey lighten-3" flat dense>
        <v-toolbar-title>Filtros</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn text small @click="$emit('cancelar')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-7">
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              :value="value.data_tipo"
              :items="tiposDeData"
              item-text="label"
              item-value="value"
              outlined
              dense
              label="Tipo de data"
              hide-details="auto"
              @input="atualizar('data_tipo', $event)"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-menu :close-on-content-click="false" v-model="displayDatepickerDataInicio" transition="scale-transition"
                    offset-y min-width="auto">
              <template v-slot:activator="{}">
                <v-text-field
                  v-model="dataInicioFormatada"
                  v-mask="'##/##/####'"
                  label="Data início"
                  autocomplete="off"
                  dense
                  hide-details="auto"
                  outlined
                  append-icon="mdi-calendar"
                  @blur="atualizar('data_inicio', formatarDataApi($event.target.value))"
                  @click:append="displayDatepickerDataInicio = true"
                />
              </template>
              <v-date-picker
                :value="value.data_inicio"
                no-title
                class="ma-0"
                @input="atualizarDataFiltro('data_inicio', formatarDataApi($event))"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu v-model="displayDatepickerDataFim" :close-on-content-click="false" transition="scale-transition"
                    offset-y min-width="auto">
              <template v-slot:activator="{}">
                <v-text-field
                  v-model="dataFimFormatada"
                  v-mask="'##/##/####'"
                  label="Data Fim"
                  dense
                  hide-details="auto"
                  outlined
                  append-icon="mdi-calendar"
                  autocomplete="off"
                  @blur="atualizar('data_fim', formatarDataApi($event.target.value));"
                  @click:append="displayDatepickerDataFim = true"
                />
              </template>
              <v-date-picker
                :value="value.data_fim"
                no-title
                class="ma-0"
                @input="atualizarDataFiltro('data_fim', formatarDataApi($event))"
              />
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-menu
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{}">
                <v-text-field
                  label="Placa do veículo"
                  dense
                  clearable
                  outlined
                  hide-details="auto"
                  @input="atualizar('placa_veiculo', $event)"
                />
              </template>
            </v-menu>
          </v-col>
          <v-col cols="12" md="8">
            <validation-provider v-slot="{}" vid="pessoa_id">
              <autocomplete-pessoas
                label="Pessoa relacionada"
                @change="atualizarPessoaRelacionadaSelecionada"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :value="value.natureza_ocorrencia_id"
              :items="naturezasOcorrencia"
              item-text="descricao"
              item-value="id"
              clearable
              outlined
              dense
              label="Natureza"
              hide-details="auto"
              :loading="carregandoBuscarNaturezas"
              :disabled="carregandoBuscarNaturezas"
              @input="atualizar('natureza_ocorrencia_id', $event)"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :value="value.situacao_ocorrencia_id"
              :items="situacoesOcorrencia"
              item-text="descricao"
              item-value="id"
              outlined
              clearable
              dense
              label="Situação"
              hide-details="auto"
              :loading="carregandoBuscarSituacoes"
              :disabled="carregandoBuscarSituacoes"
              @input="atualizar('situacao_ocorrencia_id', $event)"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :value="value.tipo_ocorrencia_id"
              :items="tiposOcorrencia"
              item-text="descricao"
              item-value="id"
              outlined
              clearable
              dense
              label="Tipo de ocorrência"
              hide-details="auto"
              :loading="carregandoBuscarTiposOcorrencia"
              :disabled="carregandoBuscarTiposOcorrencia"
              @input="atualizar('tipo_ocorrencia_id', $event)"
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-select
              :value="value.visibilidade_id"
              :items="visibilidadesOcorrencia"
              item-text="descricao"
              item-value="id"
              outlined
              clearable
              dense
              label="Visibilidade de Grupos"
              hide-details="auto"
              :loading="carregandoBuscarVisibilidades"
              :disabled="carregandoBuscarVisibilidades"
              @input="atualizar('visibilidade_id', $event)"
            />
          </v-col>
          <v-col cols="12" md="4">
            <autocomplete-estados v-model="estado_id"/>
          </v-col>
          <v-col cols="12" md="4">
            <autocomplete-cidades v-model="value.cidade_id" :estado-id="estado_id" :error-messages="[]"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="info" @click="$emit('filtrar')">
          <v-icon left v-text="'mdi-filter'"/>
          Filtrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import naturezasApi from "@/api/naturezas-ocorrencia";
import situacoesApi from "@/api/situacoes-ocorrencia";
import ocorrenciasApi from '@/api/ocorrencias';
import tiposOcorrenciaApi from '@/api/tipos-ocorrencia';
import store from "@/store";

export default {
  components: {
    AutocompleteCidades: () => import('@/components/input/InputAutocompleteCidades'),
    AutocompleteEstados: () => import('@/components/input/InputAutocompleteEstados'),
    AutocompletePessoas: () => import("@/components/input/InputAutocompletePessoas"),
  },
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: () => {
      },
    },
  },

  data() {
    return {
      estado_id: store.state.usuario.usuario.empresa.endereco.cidade.estado.id ?? null,
      displayDatepickerDataInicio: false,
      displayDatepickerDataFim: false,
      dataInicioFormatada: null,
      dataFimFormatada: null,

      carregandoBuscarVisibilidades: false,
      carregandoBuscarNaturezas: false,
      carregandoBuscarSituacoes: false,
      carregandoBuscarTiposOcorrencia: false,

      visibilidadesOcorrencia: [],
      naturezasOcorrencia: [],
      situacoesOcorrencia: [],
      tiposOcorrencia: [],

      tiposDeData: [
        { value: 'fato', label: 'Fato' },
        { value: 'cadastro', label: 'Cadastro' }
      ]
    };
  },

  mounted() {
    this.buscarVisibilidades();
    this.buscarNaturezas();
    this.buscarSituacoes();
    this.buscarTiposOcorrencia();
  },

  methods: {
    atualizar(chave, valor) {
      let updatedFilters = null;

      if (valor === null || valor.length <= 0) {
        delete this.value[chave];
        updatedFilters = {...this.value};
      } else {
        updatedFilters = {...this.value, [chave]: valor};
      }

      this.$emit("input", updatedFilters);
    },

    formatarData(data) {
      if (!data) return null;

      const [ano, mes, dia] = data.split("-");
      return `${dia}/${mes}/${ano}`;
    },

    formatarDataApi(data) {
      if (!data || data.length !== 10) return null;
      else if (data.indexOf('-') >= 0) return data;
      else if (data.indexOf('/') >= 0) {
        const [dia, mes, ano] = data.split("/");
        return `${ano}-${mes.padStart(2, "0")}-${dia.padStart(2, "0")}`;
      }
      return null;
    },

    atualizarDataFiltro(parametro, data) {
      this.atualizar(parametro, data);
      this.displayDatepickerDataInicio = false;
      this.displayDatepickerDataFim = false;
    },

    async buscarVisibilidades() {
      try {
        this.carregandoBuscarVisibilidades = true;
        const resposta = await ocorrenciasApi.buscarVisibilidades({
          por_pagina: 1000,
          pagina: 1,
        });
        this.visibilidadesOcorrencia = resposta.data.data;
      } catch (e) {
        // console.log(e);
      } finally {
        this.carregandoBuscarVisibilidades = false;
      }
    },

    async buscarNaturezas() {
      try {
        this.carregandoBuscarNaturezas = true;
        const resposta = await naturezasApi.listar({
          por_pagina: 1000,
          pagina: 1,
        });
        this.naturezasOcorrencia = resposta.data.naturezas_ocorrencia;
      } catch (e) {
        //console.log(e);
      } finally {
        this.carregandoBuscarNaturezas = false;
      }
    },

    async buscarSituacoes() {
      try {
        this.carregandoBuscarSituacoes = true;
        const resposta = await situacoesApi.listar({
          por_pagina: 1000,
          pagina: 1,
        });
        this.situacoesOcorrencia = resposta.data.situacoes_ocorrencia;
      } catch (e) {
        //console.log(e);
      } finally {
        this.carregandoBuscarSituacoes = false;
      }
    },

    async buscarTiposOcorrencia() {
      try {
        this.carregandoBuscarTiposOcorrencia = true;
        const resposta = await tiposOcorrenciaApi.listar({
          por_pagina: 1000,
          pagina: 1,
        });
        // console.log(resposta.data);
        this.tiposOcorrencia = resposta.data.tipos_ocorrencia;
      } catch (e) {
        //console.log(e);
      } finally {
        this.carregandoBuscarTiposOcorrencia = false;
      }
    },

    atualizarPessoaRelacionadaSelecionada(pessoa) {
      if (pessoa.id !== undefined) {
        this.atualizar('pessoa_id', pessoa.id);
      } else {
        this.atualizar('pessoa_id', null);
      }
    }
  },

  watch: {
    'value.data_inicio': {
      immediate: true,
      handler(data) {
        this.dataInicioFormatada = this.formatarData(data)
      },
    },
    'value.data_fim': {
      immediate: true,
      handler(data) {
        this.dataFimFormatada = this.formatarData(data)
      },
    },
  },
};
</script>

<style lang="css">
  .dialog-ocorrencias-filtros {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    .dialog-ocorrencias-filtros {
      width: 50% !important;
    }
  }
</style>
